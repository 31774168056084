.Nav-container {
  animation: nav-load 500ms ease-out;
  display: flex;
  flex-direction: row;
  height: 10vh;
  border-bottom: 5px solid #000000;
}


.Nav-solo {
  padding-top: 1.5%;
  font-weight: bolder;
  padding-left: 3%;
  font-size: 30pt;
  font-family: 'Orbitron', sans-serif;
  background: -webkit-linear-gradient(#333, rgb(0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.Nav-logo {
  flex: 1;
  
}

.Nav-links-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
}

.Nav-links {
  flex: .1;
}

.Nav-links :hover{
  border-bottom: black solid 2px;
}

@keyframes nav-load {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}