body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Nav-container {
  -webkit-animation: nav-load 500ms ease-out;
          animation: nav-load 500ms ease-out;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 10vh;
  border-bottom: 5px solid #000000;
}


.Nav-solo {
  padding-top: 1.5%;
  font-weight: bolder;
  padding-left: 3%;
  font-size: 30pt;
  font-family: 'Orbitron', sans-serif;
  background: -webkit-linear-gradient(#333, rgb(0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 2px rgba(255, 255, 255, 0.4);
}

.Nav-logo {
  -webkit-flex: 1 1;
          flex: 1 1;
  
}

.Nav-links-container {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-right: 3%;
}

.Nav-links {
  -webkit-flex: .1 1;
          flex: .1 1;
}

.Nav-links :hover{
  border-bottom: black solid 2px;
}

@-webkit-keyframes nav-load {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes nav-load {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
.Home-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100vh;
  width: 100vw;
  font-family: 'Lato', sans-serif;
  -webkit-animation: body-load 1000ms ease-in 400ms;
          animation: body-load 1000ms ease-in 400ms;
}

.Home-baby-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.Home-info-container {
  margin-top: 7%;
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 3% 3% 3% 3%;
}

.baby-one {
  background-color: white;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.photo-container {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.Home-my-photo {
  margin-left: 20%;
  height: 60%;
  width: 60%;
  margin-top: 17%;
  box-shadow: 2px 2px 2px  gray;
  border-radius: 5px;
  
}

.Home-me-synopsis {
  color: #000000;
  font-size: 16pt;
  text-shadow: 2px 2px 10px black;
}

.Home-me-description {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.baby-two {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.Home-summary {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 3%;
  margin-right: 2%;
}

.Home-summary-title {
  color: rgb(31, 36, 42);
}

.Home-summary-text {
  font-size: 14pt;
  font-weight:  bold;
}

.Home-skills {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 3%;
  font-weight:  bold;
}


@-webkit-keyframes body-load{
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}


@keyframes body-load{
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}
* {
  color: black;
  font-family: 'Lato', sans-serif;
}

.ProjectCard-image, a {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.details {
  text-decoration: none;
}

.card {
  margin: 3%;
  border-radius: 5px;
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 200px;
  opacity: 1;
  transition: opacity 300ms linear;
  -webkit-transform: scale(0.07), transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transform: scale(0.07), transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}


.ProjectCard-parent {
  width: 100vw;
  height: 100vh;
  Padding: 3%;
  margin-bottom: 5%;
  padding: 5px;
}

.cards-slider-wrapper {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  transition: -webkit-transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955), -webkit-transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955)
}
.project-title {
  margin-left: 3%;
}

.cards-slider {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.cards-slider.active-slide-0 #card-0 {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.cards-slider.active-slide-1 #card-1 {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.cards-slider.active-slide-2 #card-2 {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.cards-slider::after {
  content: '';
  display: block;
  width: 400px;
  height: 600px;
  outline: 5px solid #4E5283;
  position: absolute;
  top: 0;
  left: 0;
}

.buttons {
  background-color: white;
  width: 10%;
  height: 10%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.button-container {
  margin-top: 3%;
  margin-left: 45%;
}

