.Home-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  font-family: 'Lato', sans-serif;
  animation: body-load 1000ms ease-in 400ms;
}

.Home-baby-container {
  display: flex;
  flex-direction: row;
}

.Home-info-container {
  margin-top: 7%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 3% 3% 3% 3%;
}

.baby-one {
  background-color: white;
  flex: 1;
}

.photo-container {
  flex: 1;
}

.Home-my-photo {
  margin-left: 20%;
  height: 60%;
  width: 60%;
  margin-top: 17%;
  box-shadow: 2px 2px 2px  gray;
  border-radius: 5px;
  
}

.Home-me-synopsis {
  color: #000000;
  font-size: 16pt;
  text-shadow: 2px 2px 10px black;
}

.Home-me-description {
  flex: 1;
}

.baby-two {
  flex: 1;
  display: flex;
}

.Home-summary {
  flex: 1;
  padding: 3%;
  margin-right: 2%;
}

.Home-summary-title {
  color: rgb(31, 36, 42);
}

.Home-summary-text {
  font-size: 14pt;
  font-weight:  bold;
}

.Home-skills {
  flex: 1;
  padding: 3%;
  font-weight:  bold;
}


@keyframes body-load{
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}