* {
  color: black;
  font-family: 'Lato', sans-serif;
}

.ProjectCard-image, a {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.details {
  text-decoration: none;
}

.card {
  margin: 3%;
  border-radius: 5px;
  flex: 1;
  min-width: 200px;
  opacity: 1;
  transition: opacity 300ms linear;
  transform: scale(0.07), transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

